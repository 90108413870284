import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadNextPageFetch } from './submissionAPI';
import { RootState } from '../../app/store';

export interface Submission {
  id: string;
  dateReceived: string;
  city: string;
  images?: string[];
}

export interface SubmissionState {
  submissions: Submission[];
  status: 'idle' | 'loading' | 'failed';
  after?: string;
  isDoneLoading: boolean;
}

const initialState: SubmissionState = {
  submissions: [],
  status: 'idle',
  isDoneLoading: false
};

export const loadNextPage = createAsyncThunk(
  'submission/loadNextPage',
  async (_: void, { getState }) => {
    const after = (getState() as RootState).submission.after;

    return await loadNextPageFetch(after);
  }
);

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadNextPage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadNextPage.fulfilled, (state, action) => {
        if (action.payload.data.length === 0) {
          state.isDoneLoading = true;
        } else {
          state.after = action.payload.iterator;
          state.submissions = [...state.submissions, ...action.payload.data];
        }
        state.status = 'idle';
      })
      .addCase(loadNextPage.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export default submissionSlice.reducer;
