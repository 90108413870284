import { Submission } from "./submissionSlice";

export async function loadNextPageFetch(iterator?: string): Promise<{ iterator?: string, data: Submission[] }> {
	const params = new URLSearchParams();
	if (iterator) {
		params.set('after', iterator);
	}

	const res = await fetch(`/api/feed?${params.toString()}`);
	const data = await res.json();

	return data;
}
