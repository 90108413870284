import Images from './Images';
import styles from './Submission.module.scss';
import { Submission } from './submissionSlice';

interface Props {
  submission: Submission;
}

export function SubmissionCard({ submission }: Props) {
  const date = new Date(submission.dateReceived);

  return (
    <div className={styles.card}>
      <Images images={submission.images} />
      <div className={styles.text}>
        <div className={styles.id}>{submission.id}</div>
        {/* We need to translate the city id to the display name */}
        <div className={styles.label}>METRO CITY:</div>
        <div className={styles.value}>{submission.city}</div>
        <div className={styles.label}>SUBMITTED:</div>
        <div className={styles.datetime}>
          <span>{date.toLocaleDateString()}</span>
          <span>{date.toLocaleTimeString([],{hour: '2-digit', minute: '2-digit'})}</span>
        </div>
      </div>
    </div>
  );
}