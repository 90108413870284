import { useAppSelector } from './app/hooks';
import Submissions from './features/submissions/Submissions';

function App() {
  const submissions = useAppSelector(state => state.submission.submissions);

  return (
    <div className="App">
      <Submissions submissions={submissions} />
    </div>
  );
}

export default App;
