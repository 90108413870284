import { useRef } from "react";
import { SubmissionCard } from "../submission/SubmissionCard";
import { Submission, loadNextPage } from "../submission/submissionSlice";
import styles from './Submissions.module.scss';
import { useAppDispatch, useAppSelector } from "../../app/hooks";

type Props = {
  submissions: Submission[];
}

export default function Submissions({ submissions }: Props) {
  const dispatch = useAppDispatch();
  const scrollingRef = useRef<HTMLDivElement>(null);
  const submissionsState = useAppSelector(state => state.submission.status);

  const onScroll = () => {
    if (!scrollingRef.current) {
      return;
    }

    if (submissionsState === 'loading') {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollingRef.current;
    if (scrollHeight - (scrollTop + clientHeight) <= 1) {
      dispatch(loadNextPage());
    }
  };

  return (
    <div className={styles.container} ref={scrollingRef} onScroll={onScroll}>
      {submissions?.map(submission => <SubmissionCard key={submission.id} submission={submission} />)}
      {submissionsState === 'loading' && (
        <div>Loading...</div>
      )}
    </div>
  );
}