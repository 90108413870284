import { useMemo, useState } from 'react';
import styles from './Images.module.scss';

type Props = {
  images?: string[];
}

export default function Images({ images }: Props) {
  const [hasError, setHasError] = useState(false);

  const url = useMemo(() => {
    if (!images || images?.length === 0 || hasError) {
      return null;
    }

    return images[0];
  }, [hasError, images]);

  if (!url) {
    return <div className={styles.noImage}><span>No image</span></div>;
  }

  return (
    <img className={styles.image} alt="thumbnail" src={url} onError={() => {
      console.error('error loading image:', url);

      setHasError(true);
    }} />
  )
}